
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "App",
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },
  setup() {
    const isOpen = ref<boolean>(true)
    return {
      isOpen
    };
  },
});
