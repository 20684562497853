export const spotCheckSampledOptions = [
  {
    name: "Vendor un-Sampled",
    value: "0",
  },
  {
    name: "Vendor Sampled",
    value: "1",
  },
  {
    name: "All",
    value: "-1",
  }
];
export const controllingSampledOptions = [
  {
    name: "Controlling un-Sampled",
    value: "0",
  },
  {
    name: "Controlling Sampled",
    value: "1",
  },
  {
    name: "All",
    value: "-1",
  }
];
export const checkStatusOptions = [
  {
    name: "Vendor Check Failed",
    value: "0",
  },
  {
    name: "Vendor Check Passed",
    value: "1",
  },
  {
    name: "All",
    value: "-1",
  }
];
