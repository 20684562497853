export const spotCheckOptions = {
  "0": "",
  "1": "Passed",
  "2": "Failed",
};
export const sampleCheckOptions = {
  "0": "",
  "1": "Passed",
  "2": "Failed",
};
export const controllingCheckOptions = {
  "0": "",
  "1": "Passed",
  "2": "Failed",
  "3": "Passed",
  "4": "Failed"
};
// 获取状态为unChecked的program列表
export const unChecked: string[] = [
  "Submit For Checking",
  "Spot Checking Prepared",
  "Logic Confirmed",
  "Sample Confirmed",
];
