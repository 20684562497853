
import { computed, defineComponent, PropType, ref } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "App",
  props: {
    type: {
      type: String,
      default: 'dealer'
    }
  },
  setup(props, context) {
    const activeKey = ref<string[]>(["details", "invoice", "doc", "operation"]);
    const handleDocDownload = () => {
      context.emit('on-doc-download')
    }
    return {
      handleDocDownload,
      activeKey,
    };
  },
  components: {
    RightOutlined,
  },
});
