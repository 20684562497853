import { h } from "vue";
import { Tooltip } from "ant-design-vue";
const useOverflowText = () => {
  return ({ text }: { text: number | string }) => {
    return h(
      Tooltip as any,
      {
          title: text
      },
      h(
        "span",
        {
          style: {
            width: "100%",
            display: "block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
        text
      )
    );
  };
};
export default useOverflowText;
